import React from "react";
import Layout from "../layout/Layout";
import AboutCompanyBlock from "../blocks/static/AboutCompanyBlock";
import SEO from "../components/seo";

import enMessages from '../i18n/about/en.json'
import ruMessages from '../i18n/about/ru.json'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const AboutPage = ({ pathContext: { locale } }) => {
    return (
        <Layout locale={locale}>
            <SEO
                title={messages[locale].seo.title}
                description={messages[locale].seo.description}
            />
            <AboutCompanyBlock locale={locale}/>
        </Layout>
    )
};

export default AboutPage;
