import React from 'react';
import Block from "../Block";
import BlockSection from "../BlockSection";
import Title from "../../components/typography/Title";

import enMessages from '../../i18n/about/en.json'
import ruMessages from '../../i18n/about/ru.json'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const AboutCompanyBlock = ({locale}) => {
    return (
        <Block className="Block_theme_about" config={{
            baseBorder: true,
            animation: '3'
        }}>
            <div className="container">
                <BlockSection>
                    <Title className="Block-Title" level="h1" headerStyleNumb="5">{messages[locale].aboutCompanyBlock.title}</Title>
                    <div className="Block-Text text4" dangerouslySetInnerHTML={{ __html: messages[locale].aboutCompanyBlock.description }}/>
                </BlockSection>
            </div>
        </Block>
    );
};

export default AboutCompanyBlock;
